var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('default-template', [_c('v-layout', {
    attrs: {
      "justify-center": "",
      "align-start": ""
    }
  }, [_c('v-responsive', {
    staticClass: "sub-contents",
    attrs: {
      "width": "100wv"
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "sub_head_title clearfix"
  }, [_c('div', {
    staticClass: "fLeft"
  }, [_c('h1', [_vm._v("회사소개")])])]), _c('div', {
    staticClass: "about-section"
  }, [_c('div', {
    staticClass: "img-wrap"
  }, [_c('img', {
    attrs: {
      "src": "/images/pinbox/about-img.png",
      "alt": "건물이미지"
    }
  })]), _c('p', {
    staticClass: "page-text"
  }, [_c('strong', {
    staticClass: "color-orange"
  }, [_vm._v("주식회사 쿠폰스토리")]), _vm._v("는 "), _c('strong', {
    staticClass: "color-orange"
  }, [_vm._v("상품권 대량 매입의 표준")]), _vm._v("을 제공하며 "), _c('br', {
    staticClass: "pc"
  }), _c('strong', [_vm._v("24시간 편리하고 안전하게 구매 가능합니다")])])]), _c('div', {
    staticClass: "about-section2"
  }, [_c('div', {
    staticClass: "location"
  }, [_c('div', {
    staticClass: "location-left"
  }, [_c('ul', {
    staticClass: "list-style"
  }, [_c('li', [_c('em', [_vm._v("업종")]), _vm._v("상품권유통 / 서비스")]), _c('li', [_c('em', [_vm._v("주소")]), _vm._v("광주광역시 북구 첨단과기로208번길 43-10,723호")]), _c('li', [_c('em', [_vm._v("전화번호")]), _vm._v("1800-3712")]), _c('li', [_c('em', [_vm._v("휴일")]), _vm._v("연중 무휴(24시간 영업)")])])]), _c('div', {
    staticClass: "location-right"
  }, [_c('div', {
    staticClass: "map"
  }, [_c('iframe', {
    staticStyle: {
      "border": "0px"
    },
    attrs: {
      "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3259.2838562981065!2d126.84813005103997!3d35.22430326240553!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35718e14f40c3575%3A0xce9fc2066d99554d!2z6rSR7KO86rSR7Jet7IucIOu2geq1rCDssqjri6jqs7zquLDroZwyMDjrsojquLggNDM!5e0!3m2!1sko!2skr!4v1657531759687!5m2!1sko!2skr",
      "width": "100%",
      "height": "100%",
      "frameborder": "0",
      "allowfullscreen": "allowfullscreen",
      "aria-hidden": "false",
      "tabindex": "0"
    }
  })])])])])])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }